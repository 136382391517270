@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,600;0,700;0,800;0,900;1,600;1,700;1,800;1,900&family=Signika:wght@300&display=swap");

.ts_banner_col_image {
  background-image: url("../public/assets/images/main-banner.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.ts_main_ui_screen_image {
  background-image: url("../public/assets/images/main-ui-screen.webp");
  background-size: cover;
  background-position: top;
}

@keyframes wave {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

.wave-animation {
  animation: wave 2s ease-in-out infinite;
}


.job_description ul li {
  list-style: circle;
  margin-left: 40px;
}

.job_description h3 {
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 10ox;
}
